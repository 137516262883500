import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {observer} from 'mobx-react';
import * as C from './stack-edit_constants';

import StackEditSaveRow from './stack-edit_save-row.jsx';
import ToolBuilder from '../shared/tool_builder.jsx';
import {throwEvent} from '../../../../shared/utils/event-manager.js';

export default
@observer
class StackToolSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popularTools: [],
      searchResults: [],
      showResults: false,
      newToolName: null
    };

    // render bindings
    this.getOptions = this.getOptions.bind(this);
    this.removeTool = this.removeTool.bind(this);
    this.toolSearchFocus = this.toolSearchFocus.bind(this);
    this.toolSearchBlur = this.toolSearchBlur.bind(this);
    this.isToolSelected = this.isToolSelected.bind(this);
    this.searchResultClass = this.searchResultClass.bind(this);
    this.resetToolName = this.resetToolName.bind(this);
  }

  componentDidMount() {
    $.get('/api/v1/services/popular_services', response => {
      this.setState({popularTools: response});
    });
  }

  resetToolName() {
    this.setState({newToolName: null});
  }

  addTool(tool) {
    this.context.globalStore.addSelectedTool(tool);
    this.setState({searchResults: [], toolSearchInputText: ''});
  }
  removeTool(tool) {
    this.context.globalStore.removeSelectedTool(tool);
    this.forceUpdate();
    this.refs.toolSearchInput.focus();
  }

  toolSearchFocus() {
    this.setState({showResults: true});
  }
  toolSearchBlur() {}

  toggleShowPackages() {
    this.context.globalStore.toggleShowPackages();
  }

  componentWillUnmount() {
    clearTimeout(this._blurTimeout);
  }

  dropDownSelectTool(val) {
    this.addTool(val.value);
  }

  getOptions(input) {
    return fetch('/api/v1/services/search?q=' + input)
      .then(response => {
        return response.json();
      })
      .then(json => {
        let options = [];
        for (let i in json) {
          let tool = json[i];
          options.push({value: tool, label: tool.name});
        }
        return {options: options};
      });
  }

  shouldSendRequest() {
    return new Date().getTime() - this.state.lastRequest > 2000;
  }

  // handle form input changes
  onChange(event) {
    event = event.nativeEvent;

    this.setState({[event.target.name]: event.target.value});
    clearTimeout(this.state.requestTimeout);

    if (event.target.value !== '') {
      this.setState({
        requestTimeout: setTimeout(() => {
          $.get('/api/v1/services/search', {q: event.target.value}, data => {
            data = data || [];
            this.setState({searchResults: data, requestTimeout: undefined, searchResultIndex: -1});
          });
        }, 1000),
        searchResults: []
      });
    } else {
      this.setState({requestTimeout: undefined, searchResults: [], searchResultIndex: -1});
    }
  }

  onContinue() {
    throwEvent('stack.create.toolsList.submit');

    browserHistory.push(`${C.ONBOARDING_BASE_PATH}/tool-details`);
  }

  onKeyPress(event) {
    event = event.nativeEvent;
    if (event.key === 'ArrowDown') {
      this.setState({
        searchResultIndex: Math.min(
          this.state.searchResultIndex + 1,
          this.state.searchResults.length
        )
      });
    } else if (event.key === 'ArrowUp') {
      this.setState({searchResultIndex: Math.max(this.state.searchResultIndex - 1, 0)});
    } else if (event.key === 'Enter' && this.state.searchResultIndex > -1) {
      this.addTool(this.state.searchResults[this.state.searchResultIndex]);
    } else if (event.key === 'Escape') {
      this.setState({searchResultIndex: -1});
    }
  }

  searchResultClass(tool) {
    if (this.state.searchResultIndex === this.state.searchResults.indexOf(tool)) return 'active';
    return '';
  }

  isToolSelected(tool) {
    return (
      this.context.globalStore.selectedTools.findIndex(t => {
        return t.id === tool.id;
      }) !== -1
    );
  }

  render() {
    return (
      <div className="onboarding__tool-selection">
        {!this.context.routerProps.isUserImpersonated ? (
          <>
            <div className="tool-banner">
              <div>
                <h1>Build Your Stack</h1>
                <p className="subheader">
                  Search for or select the tools &amp; services you use in this stack
                </p>
              </div>
              <label className="show-packages">
                <input
                  type="checkbox"
                  checked={this.context.globalStore.showPackage}
                  onChange={() => this.toggleShowPackages()}
                />
                Show Packages
              </label>
            </div>
            <br />
            <ToolBuilder canCreateTools={true} multiline={true} placeholder="Search & add tools" />
            <div className="submit-links-container tools-selection-continue-container">
              <div className="submit-links">
                {this.context.globalStore.selectedTools.length > 0 && (
                  <StackEditSaveRow continuePath="/tool-details" continueText="Continue" />
                )}
              </div>
            </div>
            <div className="onboarding__tool-selection__popular-tools">
              <h3>Popular Tools</h3>
              {this.state.popularTools.map(tool => {
                if (
                  this.context.globalStore.selectedTools.findIndex(t => {
                    return t.id === tool.id;
                  }) === -1
                ) {
                  return (
                    <div
                      key={`toolselection-tool-${tool.id}`}
                      className="onboarding__tool-selection__tool hint--top"
                      data-hint={tool.name}
                      onClick={() => this.addTool(tool)}
                    >
                      <img src={tool.image_url} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={`toolselection-tool-${tool.id}`}
                      className="onboarding__tool-selection__tool remove hint--top"
                      data-hint={tool.name}
                      onClick={() => this.removeTool(tool)}
                    >
                      <img src={tool.image_url} />
                    </div>
                  );
                }
              })}
              {this.state.popularTools.length === 0 && (
                <img src={C.IMG_SPINNER} className="onboarding__tool-selection__loading" />
              )}
            </div>
          </>
        ) : (
          'Impersonated users are not allowed to edit tools'
        )}
      </div>
    );
  }
}

StackToolSelection.contextTypes = {
  routerProps: PropTypes.object,
  globalStore: PropTypes.object,
  navStore: PropTypes.object
};
