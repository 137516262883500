//EVENT MANAGER FILE TO HANDLE EVENTS

export const throwEvent = (eventName, data) => {
  if (typeof window !== 'undefined') {
    if (typeof window.trackEvent === 'function') {
      window.trackEvent(eventName, data);
    } else {
      // eslint-disable-next-line no-console
      console.warn('trackEvent not defined');
    }
  }
};

export const throwPageEvent = (eventName, data) => {
  if (typeof window !== 'undefined') {
    if (typeof window.trackPage === 'function') {
      window.trackPage(eventName, data);
    } else {
      // eslint-disable-next-line no-console
      console.warn('trackPage not defined');
    }
  }
};
