import React, {useState} from 'react';
import BaseModal from '../../../../../shared/library/modals/base/modal';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

const Container = glamorous.div({
  marginTop: 10,
  marginBottom: 80
});

const ShadowUserModal = ({title, children}) => {
  const [shadowUserModal, setShadowUserModal] = useState(true);
  return (
    shadowUserModal && (
      <Container>
        <BaseModal title={title} onDismiss={() => setShadowUserModal(false)}>
          {children}
        </BaseModal>
      </Container>
    )
  );
};

ShadowUserModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default ShadowUserModal;
