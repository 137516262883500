import React, {Component} from 'react';

class NextSteps extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <p>Next steps...</p>;
  }
}

export default NextSteps;
