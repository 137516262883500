import React, {Component} from 'react';

class ToolsVoting extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <p>tools voting</p>;
  }
}

export default ToolsVoting;
